import { default as cryptoticket_45invalidBYfR6f00sDMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/cryptoticket-invalid.vue?macro=true";
import { default as _91_slug_93N8O9MEpUVaMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/geraete/[_slug].vue?macro=true";
import { default as indexbxFx2qUN8qMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/index.vue?macro=true";
import { default as maintenancee1IWyGb0OMMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/maintenance.vue?macro=true";
import { default as routes_45infoZ0FAFoHwNtMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/routes-info.vue?macro=true";
import { default as bestaetigungvlGWLRkkg2Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/bestaetigung.vue?macro=true";
import { default as fehlerNAbEz9C2d2Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/fehler.vue?macro=true";
import { default as persoenliche_45datenb9gq541OjgMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/kundenformular/persoenliche-daten.vue?macro=true";
import { default as kundenformularCy2GGweDgWMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/kundenformular.vue?macro=true";
import { default as warenkorb_45virtuelles_45regalxFa8codn67Meta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/warenkorb-virtuelles-regal.vue?macro=true";
import { default as warenkorbdNS3jVRtkoMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/warenkorb.vue?macro=true";
import { default as zusammenfassung8bteaMXC5ZMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/zusammenfassung.vue?macro=true";
import { default as _91_46_46_46config_936C1ugAKbvLMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/suche/[...config].vue?macro=true";
import { default as transform_45paginationE4wQSjNDzDMeta } from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/suche/transform-pagination.ts?macro=true";
export default [
  {
    name: cryptoticket_45invalidBYfR6f00sDMeta?.name ?? "cryptoticket-invalid",
    path: cryptoticket_45invalidBYfR6f00sDMeta?.path ?? "/cryptoticket-invalid",
    meta: cryptoticket_45invalidBYfR6f00sDMeta || {},
    alias: cryptoticket_45invalidBYfR6f00sDMeta?.alias || [],
    redirect: cryptoticket_45invalidBYfR6f00sDMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/cryptoticket-invalid.vue").then(m => m.default || m)
  },
  {
    name: _91_slug_93N8O9MEpUVaMeta?.name ?? "geraete-_slug",
    path: _91_slug_93N8O9MEpUVaMeta?.path ?? "/geraete/:_slug()",
    meta: _91_slug_93N8O9MEpUVaMeta || {},
    alias: _91_slug_93N8O9MEpUVaMeta?.alias || [],
    redirect: _91_slug_93N8O9MEpUVaMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/geraete/[_slug].vue").then(m => m.default || m)
  },
  {
    name: indexbxFx2qUN8qMeta?.name ?? "index",
    path: indexbxFx2qUN8qMeta?.path ?? "/",
    meta: indexbxFx2qUN8qMeta || {},
    alias: indexbxFx2qUN8qMeta?.alias || [],
    redirect: indexbxFx2qUN8qMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancee1IWyGb0OMMeta?.name ?? "maintenance",
    path: maintenancee1IWyGb0OMMeta?.path ?? "/maintenance",
    meta: maintenancee1IWyGb0OMMeta || {},
    alias: maintenancee1IWyGb0OMMeta?.alias || [],
    redirect: maintenancee1IWyGb0OMMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: routes_45infoZ0FAFoHwNtMeta?.name ?? "routes-info",
    path: routes_45infoZ0FAFoHwNtMeta?.path ?? "/routes-info",
    meta: routes_45infoZ0FAFoHwNtMeta || {},
    alias: routes_45infoZ0FAFoHwNtMeta?.alias || [],
    redirect: routes_45infoZ0FAFoHwNtMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/routes-info.vue").then(m => m.default || m)
  },
  {
    name: bestaetigungvlGWLRkkg2Meta?.name ?? "shop-bestaetigung",
    path: bestaetigungvlGWLRkkg2Meta?.path ?? "/shop/bestaetigung",
    meta: bestaetigungvlGWLRkkg2Meta || {},
    alias: bestaetigungvlGWLRkkg2Meta?.alias || [],
    redirect: bestaetigungvlGWLRkkg2Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/bestaetigung.vue").then(m => m.default || m)
  },
  {
    name: fehlerNAbEz9C2d2Meta?.name ?? "shop-fehler",
    path: fehlerNAbEz9C2d2Meta?.path ?? "/shop/fehler",
    meta: fehlerNAbEz9C2d2Meta || {},
    alias: fehlerNAbEz9C2d2Meta?.alias || [],
    redirect: fehlerNAbEz9C2d2Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/fehler.vue").then(m => m.default || m)
  },
  {
    name: kundenformularCy2GGweDgWMeta?.name ?? "shop-kundenformular",
    path: kundenformularCy2GGweDgWMeta?.path ?? "/shop/kundenformular",
    meta: kundenformularCy2GGweDgWMeta || {},
    alias: kundenformularCy2GGweDgWMeta?.alias || [],
    redirect: kundenformularCy2GGweDgWMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/kundenformular.vue").then(m => m.default || m),
    children: [
  {
    name: persoenliche_45datenb9gq541OjgMeta?.name ?? "shop-kundenformular-persoenliche-daten",
    path: persoenliche_45datenb9gq541OjgMeta?.path ?? "persoenliche-daten",
    meta: persoenliche_45datenb9gq541OjgMeta || {},
    alias: persoenliche_45datenb9gq541OjgMeta?.alias || [],
    redirect: persoenliche_45datenb9gq541OjgMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/kundenformular/persoenliche-daten.vue").then(m => m.default || m)
  }
]
  },
  {
    name: warenkorb_45virtuelles_45regalxFa8codn67Meta?.name ?? "shop-warenkorb-virtuelles-regal",
    path: warenkorb_45virtuelles_45regalxFa8codn67Meta?.path ?? "/shop/warenkorb-virtuelles-regal",
    meta: warenkorb_45virtuelles_45regalxFa8codn67Meta || {},
    alias: warenkorb_45virtuelles_45regalxFa8codn67Meta?.alias || [],
    redirect: warenkorb_45virtuelles_45regalxFa8codn67Meta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/warenkorb-virtuelles-regal.vue").then(m => m.default || m)
  },
  {
    name: warenkorbdNS3jVRtkoMeta?.name ?? "shop-warenkorb",
    path: warenkorbdNS3jVRtkoMeta?.path ?? "/shop/warenkorb",
    meta: warenkorbdNS3jVRtkoMeta || {},
    alias: warenkorbdNS3jVRtkoMeta?.alias || [],
    redirect: warenkorbdNS3jVRtkoMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/warenkorb.vue").then(m => m.default || m)
  },
  {
    name: zusammenfassung8bteaMXC5ZMeta?.name ?? "shop-zusammenfassung",
    path: zusammenfassung8bteaMXC5ZMeta?.path ?? "/shop/zusammenfassung",
    meta: zusammenfassung8bteaMXC5ZMeta || {},
    alias: zusammenfassung8bteaMXC5ZMeta?.alias || [],
    redirect: zusammenfassung8bteaMXC5ZMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/shop/zusammenfassung.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46config_936C1ugAKbvLMeta?.name ?? "suche-config",
    path: _91_46_46_46config_936C1ugAKbvLMeta?.path ?? "/suche/:config(.*)*",
    meta: _91_46_46_46config_936C1ugAKbvLMeta || {},
    alias: _91_46_46_46config_936C1ugAKbvLMeta?.alias || [],
    redirect: _91_46_46_46config_936C1ugAKbvLMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/suche/[...config].vue").then(m => m.default || m)
  },
  {
    name: transform_45paginationE4wQSjNDzDMeta?.name ?? "suche-transform-pagination",
    path: transform_45paginationE4wQSjNDzDMeta?.path ?? "/suche/transform-pagination",
    meta: transform_45paginationE4wQSjNDzDMeta || {},
    alias: transform_45paginationE4wQSjNDzDMeta?.alias || [],
    redirect: transform_45paginationE4wQSjNDzDMeta?.redirect,
    component: () => import("/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/pages/suche/transform-pagination.ts").then(m => m.default || m)
  }
]