import revive_payload_client_4sVQNw7RlN from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_y9dIov5OEk from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt3-vuex-module/src/plugin.js";
import chunk_reload_client_UciE0i6zes from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_sentry_client_3cjTwoKJ1L from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/00.sentry.client.ts";
import _01_logger_client_TChMW9uPDn from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/01.logger.client.ts";
import component_library_PQFh3kiBRN from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/component-library.ts";
import dialog_client_XQOkZj7jFR from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/dialog.client.ts";
import features_plugin_gsuJhvJ16r from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/features-plugin.ts";
import global_components_PEc2PKHunz from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/global-components.ts";
import shop_backend_client_0GeTp4FVjA from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/shop-backend.client.ts";
import vue_touch_events_P3TJLnzVGD from "/srv/webapps/td-hardwaretool-frontend/production/releases/20240918064952/plugins/vue-touch-events.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_y9dIov5OEk,
  chunk_reload_client_UciE0i6zes,
  _00_sentry_client_3cjTwoKJ1L,
  _01_logger_client_TChMW9uPDn,
  component_library_PQFh3kiBRN,
  dialog_client_XQOkZj7jFR,
  features_plugin_gsuJhvJ16r,
  global_components_PEc2PKHunz,
  shop_backend_client_0GeTp4FVjA,
  vue_touch_events_P3TJLnzVGD
]